import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useStaticQuery, graphql, PageProps } from "gatsby";
import { Section } from "../../components/Core";
import PostCard from "../../components/PostCard";
import imgBrand1 from "../../assets/image/case-studies/southend-logo.png";

// path: '/case-study/' + node.frontmatter.slug,
type Frontmatter = {
  frontmatter: {
    slug: string;
    title: string;
    author: string;
    newsPara1: string;
    listImage: {
      childImageSharp: {
        fluid: {
          src: string | undefined;
        };
      };
    };
  };
};

const NewsList = () => {
  const data = useStaticQuery(graphql`
    query newsList2 {
      allMarkdownRemark(filter: { frontmatter: { type: { eq: "news" } } }) {
        nodes {
          frontmatter {
            slug
            title
            author
            newsPara1
            listImage {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  `);
  const result = data.allMarkdownRemark.nodes;

  return (
    <>
      {/* <!-- Feature section --> */}
      <Section className="position-relative">
        <Container>
          <Row className="d-flex flex-wrap align-items-stretch justify-content-start">
            {result.map((node: Frontmatter) => {
              const {
                title,
                slug,
                author,
                listImage,
                newsPara1,
              } = node.frontmatter;

              return (
                <Col
                  lg="4"
                  md="6"
                  className="mb-5 d-flex justify-content-center"
                >
                  <PostCard
                    key={slug}
                    img={listImage.childImageSharp.fluid.src}
                    subTitle={author}
                    title={title}
                    to={`/news/${slug}`}
                  >
                    {newsPara1}
                  </PostCard>
                </Col>
              );
            })}
          </Row>
        </Container>
      </Section>
    </>
  );
};

export default NewsList;
